import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

const NotFoundPage = () => (
  <>
    <section className="home">
      <StaticImage
        src="../images/main.webp"
        className="home-image"
        alt="Nie znaleziono strony"
        loading="eager"
        layout="fullWidth"
        quality={95}
      />
    </section>
    <div className="home-caption">
      <h1 className="home-info">Nie znaleziono strony</h1>
    </div>
  </>
);

export default NotFoundPage;
